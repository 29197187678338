




















































import Vue from 'vue';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';
import GlaromatUserResourceViewModel from '@/src/services/viewModel/resource/GlaromatUserResourceViewModel';

export default Vue.extend({
  layout: 'layout-print',
  auth: true,
  props: {
    value: { type: SiHaDocumentationResourceViewModel, default: undefined },
  },
  data: () => ({
    companyLogo: undefined as Promise<{data: Blob}> | undefined,
    sihaDocumentation: new SiHaDocumentationResourceViewModel(),
    sihaDocumentationId: 0 as number,
    isLoading: false,
    glaromatUser: new GlaromatUserResourceViewModel(),
  }),
  computed: {
    mobileShareLink(): string {
      const { tenant } = this.$router.currentRoute.params;
      const { workspace } = this.$router.currentRoute.params;
      return `${window.location.origin}/${tenant}/${workspace}/siha/mobile/documentation/${this.sihaDocumentation.shareId}`;
    },
    qrcodeUrl(): string {
      return `${this.$config.values['siha-qrcode-generator-url']}${this.mobileShareLink}`;
    },
  },
  async mounted() {
    if (this.$route.query?.shareId !== null) {
      const sId = this.$router.currentRoute?.params?.siHaDocumentationId as string;
      this.sihaDocumentationId = sId as unknown as number;
      await this.populateData();
    }
  },
  methods: {
    async populateData() {
      this.isLoading = true;
      this.sihaDocumentation = await this.$service.api.siHaDocumentation.getSiHaDocumentation(this.sihaDocumentationId);
      const logoResponse = await this.getGlaromatUserImagePath();
      this.companyLogo = logoResponse ? Promise.resolve(logoResponse) : undefined;
      this.isLoading = false;
    },
    async getGlaromatUserImagePath() {
      this.glaromatUser = await this.$service.api.me.getGlaromatUser();
      const glaromatUserPictureDocumentId = this.glaromatUser.companyLogoDocumentId ?? undefined;
      if (glaromatUserPictureDocumentId !== undefined) {
        const response = await this.$service.api.documents.downloadImage(glaromatUserPictureDocumentId);
        return { data: response };
      }
      return undefined;
    },
  },
});
